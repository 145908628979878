import { render, staticRenderFns } from "./AddNewOrgModal.vue?vue&type=template&id=71733481&scoped=true&"
import script from "./AddNewOrgModal.vue?vue&type=script&lang=js&"
export * from "./AddNewOrgModal.vue?vue&type=script&lang=js&"
import style0 from "./AddNewOrgModal.vue?vue&type=style&index=0&id=71733481&scoped=true&lang=css&"
import style1 from "./AddNewOrgModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71733481",
  null
  
)

export default component.exports