<template>
    <b-modal
        :visible="isVisible"
        :hide-footer="true"
        :hide-header="true"
        size="xl"
        @hide="hide()"
    >
        <h5>New Client/Organization</h5>

        <h6 class="mt-3">
            Organization/Client Default Non-Working Days/Holidays
        </h6>
        <b-row>
            <b-col cols="12">
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="radio-group-2"
                        v-model="organization.holidayType"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                    >
                        <b-form-radio value="Weekends">Weekends</b-form-radio>
                        <b-form-radio value="Sunday_Only"
                            >Sunday Only</b-form-radio
                        >
                        <b-form-radio value="US_Std">US Std</b-form-radio>
                        <b-form-radio value="CAN_Std">Canada Std</b-form-radio>
                        <b-form-radio value="US_Optional"
                            >US Optional</b-form-radio
                        >
                        <b-form-radio value="CAN_Optional"
                            >Canada Optional</b-form-radio
                        >
                        <b-form-radio value="Other">Other</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <h6 class="mt-3">
                    Client/Organization information
                </h6>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Client/Organization name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('name')"
                >
                    <b-form-input
                        :state="validateState('name')"
                        name="name"
                        v-model="$v.organization.name.$model"
                        autocomplete="off"
                        aria-describedby="name-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="name-feedback"
                    >
                        Please enter Organization Name
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Client/Organization type',
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                >
                    <b-form-group>
                        <b-form-select
                            v-model="$v.organization.Org_type.$model"
                            :options="OrgTypes"
                            text-field="Type_name"
                            value-field="type_Id"
                            :state="validateState('Org_type')"
                            aria-describedby="Org_type-feedback"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled
                                    >Organization Type</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Org_type-feedback"
                        >
                            Please select a Organization Type
                        </b-form-invalid-feedback>
                    </b-form-group>
                </CustomFloatingLabel>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Address 01',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('Address01')"
                >
                    <b-form-input
                        :state="validateState('Address01')"
                        name="name"
                        v-model="$v.organization.Address01.$model"
                        autocomplete="off"
                        aria-describedby="Address01-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Address01-feedback"
                    >
                        Please enter Address 01
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Corporation',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('Corporation')"
                >
                    <b-form-input
                        :state="validateState('Corporation')"
                        name="name"
                        v-model="$v.organization.Corporation.$model"
                        autocomplete="off"
                        aria-describedby="Corporation-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Corporation-feedback"
                    >
                        Please enter Corporation
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Address 02',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('Address02')"
                >
                    <b-form-input
                        :state="validateState('Address02')"
                        name="name"
                        v-model="$v.organization.Address02.$model"
                        autocomplete="off"
                        aria-describedby="Address02-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Address02-feedback"
                    >
                        Please enter Address 02
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Source',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('Source')"
                >
                    <b-form-input
                        :state="validateState('Source')"
                        name="name"
                        v-model="$v.organization.Source.$model"
                        autocomplete="off"
                        aria-describedby="Source-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Address02-feedback"
                    >
                        Please enter Source
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'City',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('City')"
                >
                    <b-form-input
                        :state="validateState('City')"
                        name="name"
                        v-model="$v.organization.City.$model"
                        autocomplete="off"
                        aria-describedby="City-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Address02-feedback"
                    >
                        Please enter City
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Client/Organization email',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('OrganizationEmail')"
                >
                    <b-form-input
                        :state="validateState('OrganizationEmail')"
                        name="OrganizationEmail"
                        v-model="$v.organization.OrganizationEmail.$model"
                        autocomplete="off"
                        aria-describedby="OrganizationEmail-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="OrganizationEmail-feedback"
                    >
                        Please enter Organization Email
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>

            <b-col cols="6">
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Country',
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                >
                    <b-form-group>
                        <b-form-select
                            v-model="$v.organization.CountryID.$model"
                            :options="countries"
                            text-field="CountryName"
                            value-field="CountryId"
                            :state="validateState('CountryID')"
                            @change="stateByCountry(organization.CountryID)"
                            aria-describedby="countryName-feedback"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled
                                    >Country</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="countryName-feedback"
                        >
                            Please select a Country
                        </b-form-invalid-feedback>
                    </b-form-group>
                </CustomFloatingLabel>
            </b-col>

            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Website',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('WebSte')"
                >
                    <b-form-input
                        :state="validateState('WebSte')"
                        name="name"
                        v-model="$v.organization.WebSte.$model"
                        autocomplete="off"
                        aria-describedby="WebSte-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Address02-feedback"
                    >
                        Please enter WebSte
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'State/Province',
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                >
                    <b-form-group>
                        <b-form-select
                            v-model="$v.organization.StateId.$model"
                            :options="states"
                            text-field="StateName"
                            value-field="StateId"
                            :state="validateState('StateId')"
                            aria-describedby="countryName-feedback"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled
                                    >State/Province</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="countryName-feedback"
                        >
                            Please select a State/Province
                        </b-form-invalid-feedback>
                    </b-form-group>
                </CustomFloatingLabel>
            </b-col>

            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Comments',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('Comments')"
                >
                    <b-form-textarea
                        :state="validateState('Comments')"
                        name="Comments"
                        v-model="$v.organization.Comments.$model"
                        autocomplete="off"
                        aria-describedby="Comments-feedback"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Comments-feedback"
                    >
                        Please enter Comments
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="6">
                <CustomValidateInput
                    :config="{
                        label: 'Zip',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                    :isValid="validated('Zip')"
                >
                    <b-form-input
                        :state="validateState('Zip')"
                        name="name"
                        v-model="$v.organization.Zip.$model"
                        autocomplete="off"
                        aria-describedby="Zip-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="Address02-feedback"
                    >
                        Please enter Zip
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
        </b-row>
        <b-row no-gutters style="width:50%;">
            <b-col cols="11">
                <CustomValidateInput
                    :config="{
                        label: 'Updated',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                        labelOffset: {
                            top: 2,
                            left: 18,
                        },
                    }"
                >
                    <b-form-input
                        name="Date"
                        v-model="organization.Date"
                        autocomplete="off"
                        aria-describedby="name-feedback"
                        disabled
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="name-feedback"
                    >
                        Please enter the Date
                    </b-form-invalid-feedback>
                </CustomValidateInput>
            </b-col>
            <b-col cols="1">
                <div>
                    <b-form-datepicker
                        button-only
                        right
                        v-model="organization.Date"
                        id="datepicker-invalid"
                        class="mt-2"
                    >
                    </b-form-datepicker>
                </div>
            </b-col>
        </b-row>
        <b-row no-gutters>
            <b-col cols="6"> </b-col>
            <b-col cols="6">
                <b-button
                    rounded
                    class="saveButton"
                    @click="SaveOrganization()"
                    :disabled="saveBtnDisable"
                    >Save</b-button
                >
                <b-button class="cancelSave" @click="cancelSave()"
                    >Cancel</b-button
                >
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import CustomValidateInput from '../CustomValidateInput';
import CustomFloatingLabel from '../CustomFloatingLabel';
import { commonService } from '../../services/common.service';
import { mapActions, mapMutations, mapState } from 'vuex';
import { eTrackerService } from '../../services/eTracker.service';
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CustomValidateInput,
        CustomFloatingLabel,
    },
    validations() {
        return {
            organization: {
                holidayType: { required },
                name: {
                    required,
                },
                Org_type: {
                    required,
                },
                Corporation: {
                    required,
                },
                Address01: {
                    required,
                },
                Address02: {
                    required,
                },
                City: {
                    required,
                },
                Zip: {
                    required,
                },
                CountryID: {
                    required,
                },
                StateId: {
                    required,
                },
                WebSte: {
                    required,
                },
                Source: {
                    required,
                },
                Comments: {
                    required,
                },
                OrganizationEmail: {
                    required,
                    email,
                },
            },
        };
    },
    data() {
        return {
            organization: {
                holidayType: 'CAN_Std',
                name: null,
                Org_type: null,
                Corporation: null,
                Address01: null,
                Address02: null,
                City: null,
                Zip: null,
                CountryID: null,
                StateId: null,
                WebSte: null,
                Source: null,
                Comments: null,
                Date: null,
                OrganizationEmail: null,
            },
            countries: [],
            states: [],
            saveBtnDisable: false,
            OrgTypes: [
                { type_Id: 1, Type_name: 'SubContractor' },
                { type_Id: 2, Type_name: 'Consultant' },
                { type_Id: 3, Type_name: 'Client' },
                { type_Id: 4, Type_name: 'Laboratory' },
                { type_Id: 5, Type_name: 'Unknown' },
                { type_Id: 6, Type_name: 'GHD' },
            ],
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        validateState(name) {
            const { $dirty, $error } = this.$v.organization[name];
            return $dirty ? !$error : null;
        },
        cancelSave() {
            this.$emit('hideOddNewOrgModal');
        },
        hide() {
            this.$emit('hideOddNewOrgModal');
        },
        countryList() {
            commonService
                .getCountries()
                .then((res) => (this.countries = res.data));
        },
        stateByCountry(countryId) {
            commonService
                .getStates(countryId)
                .then((res) => (this.states = res.data));
            this.organization.StateId = null;
        },
        SaveOrganization() {
            this.saveBtnDisable = true;
            this.$v.organization.$touch();
            if (this.$v.organization.$anyError) {
                this.saveBtnDisable = false;
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage:
                        'Please correct the Errors before saving.',
                });
                return;
            }
            eTrackerService
                .addNewOrganization(this.cleanData())
                .then((res) => {
                    this.setToastMessage({
                        showSuccess: true,
                        successToastMessage:
                            'Successfully added the Orgazitation',
                    });
                    this.$emit('hideOddNewOrgModal');
                })
                .catch((error) => {
                    this.setToastMessage({
                        showFailure: true,
                        failureToastMessage:
                            'Please correct the Errors before saving.',
                    });
                });
        },
        cleanData() {
            let data = {
                Org_Name: this.organization.name,
                Corporation: this.organization.Corporation,
                Org_Type: this.organization.Org_type,
                Street_Address1: this.organization.Address01,
                Address2: this.organization.Address02,
                City: this.organization.City,
                State_Province: this.organization.StateId + '',
                Zip_PostalCode: this.organization.Zip,
                Country_Id: this.organization.CountryID,
                State_Id: this.organization.StateId,
                Website: this.organization.WebSte,
                Source: this.organization.Source,
                Comment: this.organization.Comments,
                Updated: new Date(this.organization.Date).toISOString(),
                UpdateByWhom: 0,
                Org_Email: this.organization.OrganizationEmail,
                Weekends: this.organization.holidayType === 'Weekends' ? 1 : 0,
                Sunday_Only:
                    this.organization.holidayType === 'Sunday_Only' ? 1 : 0,
                US_Std: this.organization.holidayType === 'US_Std' ? 1 : 0,
                CAN_Std: this.organization.holidayType === 'CAN_Std' ? 1 : 0,
                US_Optional:
                    this.organization.holidayType === 'US_Optional' ? 1 : 0,
                CAN_Optional:
                    this.organization.holidayType === 'CAN_Optional' ? 1 : 0,
                Other: this.organization.holidayType === 'Other' ? 1 : 0,
            };
            return data;
        },
    },
    watch: {
        isVisible: function(newVal) {
            if (newVal) {
                setTimeout(() => {
                    this.organization.Date = new Date()
                        .toISOString()
                        .substr(0, 10);
                }, 2000);
            } else {
                this.organization.Date = null;
            }
        },
    },
    computed: {
        validated() {
            return (status) => {
                const { $dirty } = this.$v.organization[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
    },
    created() {
        this.countryList();
    },
};
</script>
<style scoped>
.my-custom-floating-label {
    margin-bottom: 20px;
    display: inline-block;
}

.errorMsg {
    margin-top: -20px;
    margin-bottom: 20px;
}

.errorMsg_input {
    position: relative;
    top: -20px;
    width: 499px;
}

.errorMsg_input span {
    float: right;
    position: relative;
    top: -40px;
    right: 15px;
    /* right: 88px; */
}

.normal_input {
    height: 48px !important;
}

.textarea_input {
    height: 95px !important;
}

select {
    appearance: none;
    border-radius: 0px;
    height: 48px !important;
    width: 475px;
}

select.selectbox {
    border: 1px solid #c6c6c6;
    padding-left: 7px;
    color: -internal-light-dark(black, white);
}

.selectbox:hover {
    border: 1px solid #c6c6c6;
}

.smalltext {
    color: #000;
    margin: -15px 0px 20px;
    font-size: 13px;
}

.typeAhead {
    border-radius: 0px !important;
    height: 48px !important;
    width: 475px;
}

.form-floating-label textarea {
    margin-bottom: 0;
    color: #626262;
    font-size: 16px;
    /* font-weight: 300; */
    padding: 35px 1rem 1rem 20px !important;
    width: 475px;
    height: 134px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
    border-radius: 3px;
}
.form-floating-label label {
    color: #626262;
    font-size: 16px;
    position: absolute;
    left: 17px;
    padding-top: 10px;
    transition: all 0.2s ease-in;
    font-family: var(--ffr);
}

.form-floating-label textarea ~ label {
    font-size: 16px;
    font-weight: 400;
}
.form-floating-label textarea:focus,
.form-floating-label.has-value textarea {
    border-radius: 0px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
}
.form-floating-label textarea:focus ~ label,
.form-floating-label.has-value textarea ~ label {
    font-size: 12px;
    color: rgb(98, 98, 98);
    font-weight: 400;
}

.contactinfo {
    color: #161616;
    font-size: 25px;
}

.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.crossIcon {
    margin-left: 150px;
    margin-top: -25px;
    width: 15px;
}
.input__container.input__container--content input,
.input__container.input__container--content .label__placeholder {
    transform: translate3d(0, 6px, 0);
    /* width: 94% !important; */
    /* left:12px; */
}
.sm-text {
    margin-top: 0 !important;
}
small {
    color: #515151;
    font-family: var(--ffr);
    font-size: 14px;
}
.nameColor {
    color: #515151;
}
.emailColor {
    color: #1d1d1d;
}
.sm-cancel {
    cursor: pointer;
    margin-right: -65px;
}
.normal_input_mask {
    height: 36px !important;
    font-size: 16px !important;
    top: 4px;
    color: #000;
}
.font14 {
    font-size: 14px !important;
}
@media only screen and (min-width: 768px) {
    .admin1.sub_header_bg {
        height: 120px !important;
    }
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem)
            calc(0.75em + 0.375rem) no-repeat !important;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #c6c6c6 !important;
    padding-right: calc(0.75em + 2.3125rem);
}
.contact-type-container > *,
.contact-name-container > * {
    border: none;
}
.contact-container {
    border-bottom: 1px solid #ccc;
}
.contact-remove,
.contact-add {
    display: flex;
    justify-content: flex-end;
}
.addNewContact-container {
    border-bottom: 1px solid #ccc;
}
.contact-container:last-child {
    border: none;
}
#addNContct {
    margin-bottom: 20px;
    min-width: 140px;
}
.contact-type-new {
    line-height: 3;
    height: 48px;
}
.bottom-small-view {
    display: none;
}
.m-view-b-t {
    display: flex;
}
.m-bottom-btn-t {
    display: block;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .bottom-small-view {
        display: flex;
    }
    .m-view-b-t,
    .m-bottom-btn-t {
        display: none;
    }
}
.error {
    color: #f00;
    display: block;
    top: -15px;
    position: relative;
}
.saveButton {
    background-color: #006395;
    width: 100px;
    position: relative;
    float: right;
    margin-right: 10px;
}
.cancelSave {
    position: relative;
    float: right;
    margin-right: 10px;
}
</style>
<style lang="scss">
.newContact-container {
    & .FloatingTool,
    .my-custom-floating-label {
        width: 100% !important;
        & .input__container {
            width: 100% !important;
            padding-left: 11px !important;
            & .custom-select {
                width: 100% !important;
            }
        }
    }
}
</style>
