<template>
    <div v-if="showPage">
        <Header v-bind:showVersionToggle="false" />
        <div
            fluid
            class="container-fluid adduser sub_header_bg"
            :class="adminrole != 'admin' ? 'admin1' : ''"
        >
            <div class="container p-0">
                <div class="mb-3 row">
                    <div class="m-p-0 col">
                        <a href="javascript:void(0)" @click="navigateProjects">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a
                                v-if="adminrole == 'admin'"
                                class="floar-left ml-1 back"
                            >
                                Back to dashboard</a
                            >
                            <span v-else class="floar-left ml-1">
                                Back to eTracker Projects</span
                            >
                        </a>
                    </div>
                </div>

                <div class="vh-10 text-left row" align-v="center">
                    <div class="pt-2 m-p-0 col col-lg-9 col-md-7 col-sm-6">
                        <h1 class="">{{ ProjectText }}</h1>
                    </div>
                    <div
                        class="align-items-center m-view-b-t col col-lg-1 col-md-2 col-sm-2 col-2"
                    >
                        <p
                            class="text-center text-primary sm-cancel pr-2 m-0"
                            @click="navigateProjects"
                        >
                            Cancel
                        </p>
                    </div>
                    <div
                        class="text-right addproject m-bottom-btn-t col-4 col col-lg-2 col-md-3 col-sm-4"
                    >
                        <button
                            rounded
                            class="buttonSelect btn"
                            id="saveProject"
                            @click="addProject()"
                            :disabled="saveBtnDisable"
                        >
                            {{ saveButtonText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-5 m-mt-10 pb-5 mb-5">
            <div class="addproject">
                <div class="pb-1 mb-3 row">
                    <div class="blue-color col col-7">
                        <b-form>
                            <div class="top-add">
                                <CustomValidateInput
                                    :config="{
                                        label: 'Project Number',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('ProjectNumber')"
                                >
                                    <b-form-input
                                        :state="validateState('ProjectNumber')"
                                        name="ProjectNumber"
                                        v-model="
                                            $v.projects.ProjectNumber.$model
                                        "
                                        autocomplete="off"
                                        aria-describedby="projectNumber-feedback"
                                        maxLength="10"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="projectNumber-feedback"
                                    >
                                        Please enter valid project number
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Task',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('Task')"
                                >
                                    <b-form-input
                                        :state="validateState('Task')"
                                        name="Task"
                                        v-model="$v.projects.Task.$model"
                                        autocomplete="off"
                                        aria-describedby="task-feedback"
                                        maxlength="6"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="task-feedback"
                                    >
                                        Please enter task
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Subtask',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('Subtask')"
                                >
                                    <b-form-input
                                        :state="validateState('Subtask')"
                                        name="Subtask"
                                        maxlength="10"
                                        v-model="$v.projects.Subtask.$model"
                                        autocomplete="off"
                                        aria-describedby="subtask-feedback"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="subtask-feedback"
                                    >
                                        Please enter sub task
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Project Name',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('ProjectName')"
                                >
                                    <b-form-input
                                        :state="validateState('ProjectName')"
                                        name="ProjectName"
                                        v-model="$v.projects.ProjectName.$model"
                                        autocomplete="off"
                                        aria-describedby="projectName-feedback"
                                        maxLength="50"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="projectName-feedback"
                                    >
                                        Please enter project name
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>
                                <div>
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{ label: 'Client' }"
                                        :isValid="validated('Org_Id')"
                                    >
                                        <b-form-group>
                                            <b-form-select
                                                v-model="
                                                    $v.projects.Org_Id.$model
                                                "
                                                :options="organizations"
                                                text-field="Org_Name"
                                                value-field="Org_Id"
                                                :state="validateState('Org_Id')"
                                                aria-describedby="org_Id-feedback"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option
                                                        :value="''"
                                                        disabled
                                                        >Client</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                            <b-form-invalid-feedback
                                                class="invalid-text-custom"
                                                id="clientName-feedback"
                                            >
                                                Please select a client
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </CustomFloatingLabel>
                                    <b-button
                                        style="position: relative;float: right; margin-top:6px;background-color:#00496d"
                                        @click="openOddNewOrgModal()"
                                    >
                                        New client
                                    </b-button>
                                </div>
                                <CustomValidateInput
                                    :config="{
                                        label: 'External Consultant Reference',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <b-form-input
                                        name="Ecr"
                                        v-model="$v.projects.Ecr.$model"
                                        autocomplete="off"
                                        maxLength="50"
                                    >
                                    </b-form-input>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Project Comments',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <b-form-input
                                        name="Comments"
                                        v-model="$v.projects.Comments.$model"
                                        autocomplete="off"
                                        maxlength="255"
                                    >
                                    </b-form-input>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Sitecode',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('Sitecode')"
                                >
                                    <b-form-input
                                        name="Sitecode"
                                        v-model="$v.projects.Sitecode.$model"
                                        autocomplete="off"
                                        :state="validateState('Sitecode')"
                                        aria-describedby="Sitecode-feedback"
                                        maxlength="11"
                                    >
                                    </b-form-input
                                    ><b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="Sitecode-feedback"
                                    >
                                        Please enter a valid Sitecode number
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>
                            </div>
                        </b-form>
                    </div>
                </div>

                <div class="newContact-container row ">
                    <div class="col">
                        <div class="roles row mb-2 pb-2">
                            <div class="col col-lg-5 col-sm-6 col-xs-12">
                                Contact Type
                            </div>
                            <div class="col col-lg-5 col-sm-6 col-xs-12">
                                Contact Name
                                <b-button
                                    style="position: relative;float: right; background-color:#00496d"
                                    @click="addContactToDropDown()"
                                >
                                    New contact
                                </b-button>
                            </div>
                        </div>
                        <div class="top-add">
                            <div v-if="!isEditing" class="mb-2 col pb-2 px-0">
                                <div
                                    v-for="(contactType,
                                    index) in projects.Contacts"
                                    v-bind:key="index"
                                    class="row"
                                >
                                    <div
                                        class="col col-lg-5 col-sm-6 col-xs-12 contact-type-new"
                                    >
                                        <div>
                                            {{ contactType.Contact_Type }}
                                        </div>
                                    </div>
                                    <div
                                        class="col col-lg-5 col-sm-6 col-xs-12"
                                    >
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Contact Name',
                                                labelOffset: {
                                                    top: 2,
                                                    left: 18,
                                                },
                                            }"
                                            ><b-form-group>
                                                <b-form-select
                                                    v-model="
                                                        findContactByIdentifier(
                                                            contactType.Contact_Type_Id
                                                        ).Contact_Id
                                                    "
                                                    :options="contacts"
                                                    text-field="name"
                                                    value-field="Contact_Id"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="''"
                                                            disabled
                                                            >Contact</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </b-form-group>
                                        </CustomFloatingLabel>
                                    </div>
                                </div>
                            </div>
                            <div class="row addNewContact-container">
                                <div class="col col-lg-5 col-sm-6 col-xs-12">
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Contact Type',
                                            labelOffset: {
                                                top: 2,
                                                left: 18,
                                            },
                                        }"
                                    >
                                        <b-form-group>
                                            <b-form-select
                                                v-model="
                                                    projects.Contact_Type_Id
                                                "
                                                :options="ContactTypes"
                                                text-field="Contact_Type"
                                                value-field="Contact_Type_Id"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="''"
                                                        disabled
                                                        >Contact
                                                        Type</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                    </CustomFloatingLabel>
                                </div>
                                <div class="col col-lg-5 col-sm-6 col-xs-12 ">
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Contact Name',
                                            labelOffset: {
                                                top: 2,
                                                left: 18,
                                            },
                                        }"
                                        ><b-form-group>
                                            <b-form-select
                                                v-model="projects.Contact_Id"
                                                :options="contacts"
                                                text-field="name"
                                                value-field="Contact_Id"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="''"
                                                        disabled
                                                        >Contact</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                    </CustomFloatingLabel>
                                </div>
                                <div
                                    class="contact-add col col-lg-2 col-sm-12 col-xs-12"
                                >
                                    <button
                                        rounded
                                        id="addNContct"
                                        class="buttonSelect mt-auto"
                                        @click="addContact"
                                        :disabled="checkInputIds"
                                    >
                                        New project contact
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            class="contact-container py-1  row mx-1"
                            v-for="(item, index) in ContactsDisplay"
                            :key="index"
                        >
                            <div class="contact-type-container col col-5">
                                <p class="">{{ item.ContactType }} :</p>
                            </div>
                            <div class="contact-name-container col col-5">
                                <p class="">
                                    {{ item.Contact }}
                                </p>
                            </div>
                            <div class=" contact-remove col col-2">
                                <button
                                    class="btn btn-outline-secondary"
                                    v-on:click="getTheSelectedOne(item)"
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="container bottom-small-view flex-row justify-content-end pb-4 mt-2"
        >
            <div class="my-auto mr-2">
                <p
                    class="text-center text-primary sm-cancel pr-2 m-0"
                    @click="navigateProjects"
                >
                    Cancel
                </p>
            </div>
            <div>
                <button
                    rounded
                    class="buttonSelect btn"
                    id="saveProject"
                    @click="addProject()"
                    :disabled="saveBtnDisable"
                >
                    {{ saveButtonText }}
                </button>
            </div>
        </div>

        <Footer />

        <AccessPermissionModal ref="permission-modal" />
        <AddNewContactModal
            :isVisible="addContactIsVisible"
            :organizations="organizations"
            @hideAddNewContact="hideAddNewContact()"
        />
        <AddNewOrgModal
            :isVisible="addOrgIsVisible"
            @hideOddNewOrgModal="hideOddNewOrgModal()"
        />

        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
            @hideToast="hideToast"
        />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import Vue from 'vue';
import router from '../../router';
import { mapActions, mapMutations, mapState } from 'vuex';
import CustomFloatingLabel from '../CustomFloatingLabel';
import Header from '../Header';
import Footer from '../../components/Footer';
import { eTrackerService } from '../../services/eTracker.service';
import AccessPermissionModal from '../AccessPermissionModal';
import ToastMessages from '../ToastMessages.vue';
import { MaskedTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';
Vue.use(MaskedTextBoxPlugin);
import { validationMixin } from 'vuelidate';
import { required, numeric, integer } from 'vuelidate/lib/validators';
import CustomValidateInput from '../CustomValidateInput';
import AddNewContactModal from './AddNewContactModal.vue';
import AddNewOrgModal from './AddNewOrgModal.vue';

export default {
    mixins: [validationMixin],
    name: 'User_add',
    components: {
        Header,
        Footer,
        CustomFloatingLabel,
        AccessPermissionModal,
        ToastMessages,
        CustomValidateInput,
        AddNewContactModal,
        AddNewOrgModal,
    },
    beforeDestroy() {
        localStorage.removeItem('adminrole');
    },
    validations() {
        return {
            projects: {
                ProjectNumber: {
                    required,
                    numeric,
                },
                Task: {
                    required,
                },
                Subtask: {
                    required,
                },
                ProjectName: {
                    required,
                },

                Ecr: {},
                Comments: {},
                Sitecode: { required, integer },
                Org_Id: {
                    required,
                },
                Contacts: {
                    $each: {
                        Contact_Type_Id: {},
                        Contact_Id: {},
                    },
                },
                Contact_Type_Id: {},
                Contact_Id: {},
            },
        };
    },
    data() {
        return {
            disabled: 0,
            buttonStatus: true,
            saveButtonText: 'Save new project',
            saveBtnDisable: false,
            ProjectText: 'Add New Project',
            cancelShow: false,
            organizations: null,
            ContactTypes: null,
            ContactTypesNew: [],
            contacts: [],
            ContactsDisplay: [],
            ProjectContacts: [],
            projects: {
                ProjectNumber: null,
                Task: '****',
                Subtask: '****',
                ProjectName: null,
                Ecr: null,
                Comments: null,
                Sitecode: null,
                Org_Id: '',
                Org_Name: null,
                Dug: '',
                Contacts: [],
                Contact_Type_Id: '',
                Contact_Type: null,
                Contact_Id: '',
                First_Name: '',
                Last_Name: '',
                ContactName: '',
            },
            adminrole: null,
            singleProject: [],
            showPage: true,
            buttonStatusSave: true,
            AllInput: [],
            counter: 0,
            disableBtn: false,
            isEditing: false,
            etrackerProjectId: '',
            addContactIsVisible: false,
            addOrgIsVisible: false,
        };
    },

    async created() {
        if (this.$route.params.id) {
            this.isEditing = true;
            this.etrackerProjectId = this.$route.params.id;
        }
        try {
            await Promise.all([
                this.organizationList(),
                this.ContactTypeList(),
                this.ContactList(),
            ]);
        } catch (error) {
            console.error('Error initializing data:', error);
        }

        if (this.isEditing) {
            this.saveButtonText = 'Save changes';
            this.initializeInputs();
        }
    },
    mounted() {
        this.pagePermit();
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),

        checkDuplicateContactType(ContactType_Id, Contact_Id) {
            var isDuplicate = this.ProjectContacts.some(
                (contact) => contact.Contact_Type_Id == ContactType_Id
            );
            if (!this.isEditing) {
                const assignedContact =
                    this.projects.Contacts.find(
                        (contact) => contact.Contact_Type_Id == ContactType_Id
                    ) ||
                    this.ProjectContacts.find(
                        (contact) =>
                            contact.Contact_Type_Id == ContactType_Id &&
                            contact.Contact_Id == Contact_Id
                    );
                isDuplicate =
                    assignedContact && assignedContact.Contact_Id !== ''
                        ? true
                        : false;
            }
            return isDuplicate;
        },
        findContactByIdentifier(identifier) {
            return (
                this.projects.Contacts.find(
                    (contact) => contact.Contact_Type_Id == identifier
                ) || {}
            );
        },
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapActions('eTracker', {
            getOrganizations: 'getOrganizations',
            getContactTypes: 'getContactTypes',
            getContacts: 'getContacts',
        }),
        validateState(name) {
            const { $dirty, $error } = this.$v.projects[name];
            return $dirty ? !$error : null;
        },
        getTheSelectedOne(item) {
            const index = this.ContactsDisplay.indexOf(item);
            if (index > -1) {
                this.ContactsDisplay.splice(index, 1);
                this.ProjectContacts.splice(index, 1);
            }
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('eTrackerAddProject')) {
                    vm.showPage = true;
                }
            });
            if (!this.showPage) {
                this.$refs['permission-modal'].showPermissionModal();
            }
        },
        async organizationList() {
            try {
                if (
                    this.$store.state.eTracker.organizations.items === undefined
                ) {
                    await this.getOrganizations();
                }

                this.organizations = this.$store.state.eTracker.organizations.items.filter(
                    (item) => item.Org_Name !== '  '
                );

                this.organizations.sort((a, b) =>
                    a.Org_Name.localeCompare(b.Org_Name)
                );
            } catch (error) {
                console.error('Error fetching organizations:', error);
            }
        },

        async ContactTypeList() {
            try {
                if (
                    this.$store.state.eTracker.ContactTypes.items === undefined
                ) {
                    await this.getContactTypes();
                }

                this.ContactTypes = this.$store.state.eTracker.ContactTypes.items.filter(
                    (item) => item.Contact_Type !== 'none'
                );

                this.ContactTypes.sort((a, b) =>
                    a.Contact_Type.localeCompare(b.Contact_Type)
                );

                if (!this.isEditing) {
                    this.ContactTypesNew = this.ContactTypes.filter((element) =>
                        [
                            'cra chemist',
                            'chemist (2)',
                            'cra data analyst',
                            'field leader',
                            'lab project manager',
                            'lab project manager (2)',
                            'lead consultant pm',
                            'cra project manager',
                            'site contact',
                        ].includes(element.Contact_Type.toLowerCase())
                    );

                    this.ContactTypes = this.ContactTypes.filter((contact) => {
                        return !this.ContactTypesNew.some(
                            (contactNew) =>
                                contactNew['Contact_Type_Id'] ==
                                contact['Contact_Type_Id']
                        );
                    });

                    this.ContactTypesNew.forEach((element) => {
                        this.projects.Contacts.push({
                            ...element,
                            Contact_Id: '',
                        });
                    });

                    this.ContactTypesNew.sort((a, b) =>
                        a.Contact_Type.localeCompare(b.Contact_Type)
                    );
                }
            } catch (error) {
                console.error('Error fetching ContactTypes:', error);
            }
        },

        async ContactList() {
            try {
                if (this.$store.state.eTracker.Contacts.items === undefined) {
                    await this.getContacts();
                }
                const data = this.$store.state.eTracker.Contacts.items;
                data.forEach((element) => {
                    if (element.First_Name !== '' || element.Last_Name !== '') {
                        element.name =
                            element.First_Name + ' ' + element.Last_Name;
                        this.contacts.push(element);
                    }
                });

                this.contacts.sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.error('Error fetching Contacts:', error);
            }
        },

        addContact() {
            //var txt = "";
            var ContactType = this.ContactTypes.find(
                (o) => o.Contact_Type_Id == this.projects.Contact_Type_Id
            );
            var Contact = this.contacts.find(
                (o) => o.Contact_Id == this.projects.Contact_Id
            );
            //txt = ContactType.Contact_Type + ": " + Contact.name;
            const ProjectContact = {
                Contact_Type_Id: this.projects.Contact_Type_Id,
                Contact_Id: this.projects.Contact_Id,
            };

            if (
                this.checkDuplicateContactType(
                    ContactType.Contact_Type_Id,
                    Contact.Contact_Id
                )
            ) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage:
                        'The selected Contact type is already assigned!',
                });
            } else {
                this.ProjectContacts.push(ProjectContact);
                this.ContactsDisplay.push({
                    ContactType: ContactType.Contact_Type,
                    Contact: Contact.name,
                });
                this.projects.Contact_Type_Id = '';
                this.projects.Contact_Id = '';
            }
        },

        navigateProjects: function() {
            if (this.adminrole == 'admin') {
                router.go(-1);
            } else {
                router.push(`/eTracker`);
            }
        },

        addProject() {
            this.saveBtnDisable = true;
            this.$v.projects.$touch();
            if (this.$v.projects.$anyError) {
                this.saveBtnDisable = false;
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage:
                        'Please correct the Errors before saving.',
                });
                return;
            }
            if (!this.isEditing) {
                this.addContactNewProject();
            }
            //var ProjectID = 0;
            var CreatedOrSaved = 'created';

            if (this.etrackerProjectId) {
                CreatedOrSaved = 'saved';
            }

            this.ProjectContacts.forEach((element) => {
                element.Project_Id = this.etrackerProjectId
                    ? this.etrackerProjectId
                    : 0;
                element.User_Stamp = 0;
                element.Date_Stamp = new Date();
            });

            const projectData = {
                Project: {
                    Project_Id: this.etrackerProjectId
                        ? this.etrackerProjectId
                        : 0,
                    Org_Id: this.projects.Org_Id,
                    Project_Number: this.projects.ProjectNumber,
                    CRA_SubCode: this.projects.Subtask,
                    CRA_Task_Number: this.projects.Task,
                    Project_Name: this.projects.ProjectName,
                    External_Consultant_Project_Reference: this.projects.Ecr,
                    Comments: this.projects.Comments,
                    SiteCode: this.projects.Sitecode,
                    Default_Unit_group: this.projects.Dug,
                },
                ProjectContacts: this.ProjectContacts,
            };

            eTrackerService
                .saveProject(projectData)
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        this.setToastMessage({
                            showSuccess: true,
                            successToastMessage:
                                'project ' +
                                CreatedOrSaved +
                                ' successfully, Project_Id=' +
                                res.data,
                        });

                        setTimeout(() => {
                            router.push(`/eTracker`);
                        }, 2000);
                    } else {
                        this.setToastMessage({
                            showFailure: true,
                            failureToastMessage: res.data.Message,
                        });
                    }
                    this.saveBtnDisable = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.setToastMessage({
                        showFailure: true,
                        failureToastMessage: error.response.data.Message,
                    });
                    this.saveBtnDisable = false;
                });
        },
        addContactNewProject() {
            //var txt = "";
            this.projects.Contacts.forEach((contact) => {
                var ContactType = this.ContactTypesNew.find(
                    (o) => o.Contact_Type_Id == contact.Contact_Type_Id
                );
                var Contact = this.contacts.find(
                    (o) => o.Contact_Id == contact.Contact_Id
                );
                if (Contact && Contact.Contact_Id) {
                    //txt = ContactType.Contact_Type + ": " + Contact.name;
                    const ProjectContact = {
                        Contact_Type_Id: contact.Contact_Type_Id,
                        Contact_Id: contact.Contact_Id,
                    };
                    if (
                        !this.ProjectContacts.some(
                            (pc) =>
                                pc.Contact_Type_Id === contact.Contact_Type_Id
                        ) &&
                        !this.ContactsDisplay.some(
                            (cd) => cd.ContactType === ContactType.Contact_Type
                        )
                    ) {
                        this.ProjectContacts.push(ProjectContact);

                        this.ContactsDisplay.push({
                            ContactType: ContactType.Contact_Type,
                            Contact: Contact.name,
                        });
                    }
                }
            });
        },
        initializeInputs() {
            eTrackerService
                .getProjectById(this.etrackerProjectId)
                .then((res) => {
                    this.singleProject = res.data;
                    this.projects.Org_Id = this.singleProject.Org_Id;
                    this.projects.ProjectNumber = this.singleProject.Project_Number;
                    this.projects.Subtask = this.singleProject.CRA_SubCode;
                    this.projects.Task = this.singleProject.CRA_Task_Number;
                    this.projects.ProjectName = this.singleProject.Project_Name;
                    this.projects.Ecr = this.singleProject.External_Consultant_Project_Reference;
                    this.projects.Comments = this.singleProject.Comments;
                    this.projects.Sitecode = this.singleProject.SiteCode;
                    this.projects.Dug = this.singleProject.Default_Unit_group;

                    if (
                        this.projects.ProjectNumber &&
                        this.projects.Task &&
                        this.projects.Subtask &&
                        this.projects.ProjectName &&
                        this.projects.Ecr &&
                        this.projects.Comments &&
                        this.projects.Sitecode
                    ) {
                        this.buttonStatusSave = false;
                    }
                    if (this.projects.ProjectNumber) {
                        this.AllInput.push('ProjectNumber');
                    }
                    if (this.projects.Task) {
                        this.AllInput.push('Task');
                    }
                    if (this.projects.Subtask) {
                        this.AllInput.push('Subtask');
                    }
                    if (this.projects.ProjectName) {
                        this.AllInput.push('ProjectName');
                    }
                    if (this.projects.Ecr) {
                        this.AllInput.push('Ecr');
                    }
                    if (this.projects.Comments) {
                        this.AllInput.push('Comments');
                    }
                    if (this.projects.Sitecode) {
                        this.AllInput.push('Sitecode');
                    }
                    this.ProjectText = 'Edit Project';
                });
            eTrackerService
                .getProjectContactsForProject(this.etrackerProjectId)
                .then((res) => {
                    this.ProjectContacts = res.data;
                    //var txt = "";
                    var ContactType = '';
                    var Contact = '';
                    var ContactTypeNameList = document.getElementById(
                        'ContactTypeNameList'
                    );
                    var ProjectContact;
                    var FirstContact = true;

                    this.ProjectContacts.forEach((element) => {
                        this.projects.Contacts.push({
                            Contact_Type_Id: element.Contact_Type_Id,
                            Contact_Id: element.Contact_Id,
                        });

                        // this.projects.Contact_Type_Id = element.Contact_Type_Id;
                        // this.projects.Contact_Id = element.Contact_Id;

                        ContactType = element.contact_type;
                        Contact = element.name;
                        //txt = ContactType + ": " + Contact;
                        this.ContactsDisplay.push({
                            ContactType: ContactType,
                            Contact: Contact,
                        });
                    });
                });
        },
        hideToast() {
            this.setToastMessage({
                showFailure: false,
            });
            this.setToastMessage({
                showSuccess: false,
            });
        },
        addContactToDropDown() {
            this.addContactIsVisible = true;
        },
        async hideAddNewContact() {
            this.addContactIsVisible = false;
            await this.getContacts();
            const data = this.$store.state.eTracker.Contacts.items;
            data.forEach((element) => {
                if (element.First_Name !== '' || element.Last_Name !== '') {
                    element.name = element.First_Name + ' ' + element.Last_Name;
                    this.contacts.push(element);
                }
            });

            this.contacts.sort((a, b) => a.name.localeCompare(b.name));
        },
        async hideOddNewOrgModal() {
            await this.getOrganizations();
            this.organizations = this.$store.state.eTracker.organizations.items.filter(
                (item) => item.Org_Name !== '  '
            );

            this.organizations.sort((a, b) =>
                a.Org_Name.localeCompare(b.Org_Name)
            );
            this.addOrgIsVisible = false;
        },
        openOddNewOrgModal() {
            this.addOrgIsVisible = true;
        },
    },

    computed: {
        validated() {
            return (status) => {
                const { $dirty } = this.$v.projects[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
        checkInputIds() {
            return (
                this.projects.Contact_Type_Id == '' ||
                this.projects.Contact_Id == ''
            );
        },
        ...mapState('common', [
            'showSuccess',
            'showFailure',
            'successToastMessage',
            'failureToastMessage',
        ]),
    },
};
</script>

<style scoped>
.my-custom-floating-label {
    margin-bottom: 20px;
    display: inline-block;
}

.errorMsg {
    margin-top: -20px;
    margin-bottom: 20px;
}

.errorMsg_input {
    position: relative;
    top: -20px;
    width: 499px;
}

.errorMsg_input span {
    float: right;
    position: relative;
    top: -40px;
    right: 15px;
    /* right: 88px; */
}

.normal_input {
    height: 48px !important;
}

.textarea_input {
    height: 95px !important;
}

select {
    appearance: none;
    border-radius: 0px;
    height: 48px !important;
    width: 475px;
}

select.selectbox {
    border: 1px solid #c6c6c6;
    padding-left: 7px;
    color: -internal-light-dark(black, white);
}

.selectbox:hover {
    border: 1px solid #c6c6c6;
}

.smalltext {
    color: #000;
    margin: -15px 0px 20px;
    font-size: 13px;
}

.typeAhead {
    border-radius: 0px !important;
    height: 48px !important;
    width: 475px;
}

.form-floating-label textarea {
    margin-bottom: 0;
    color: #626262;
    font-size: 16px;
    /* font-weight: 300; */
    padding: 35px 1rem 1rem 20px !important;
    width: 475px;
    height: 134px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
    border-radius: 3px;
}
.form-floating-label label {
    color: #626262;
    font-size: 16px;
    position: absolute;
    left: 17px;
    padding-top: 10px;
    transition: all 0.2s ease-in;
    font-family: var(--ffr);
}

.form-floating-label textarea ~ label {
    font-size: 16px;
    font-weight: 400;
}
.form-floating-label textarea:focus,
.form-floating-label.has-value textarea {
    border-radius: 0px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
}
.form-floating-label textarea:focus ~ label,
.form-floating-label.has-value textarea ~ label {
    font-size: 12px;
    color: rgb(98, 98, 98);
    font-weight: 400;
}

.contactinfo {
    color: #161616;
    font-size: 25px;
}

.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.crossIcon {
    margin-left: 150px;
    margin-top: -25px;
    width: 15px;
}
.input__container.input__container--content input,
.input__container.input__container--content .label__placeholder {
    transform: translate3d(0, 6px, 0);
    /* width: 94% !important; */
    /* left:12px; */
}
.sm-text {
    margin-top: 0 !important;
}
small {
    color: #515151;
    font-family: var(--ffr);
    font-size: 14px;
}
.nameColor {
    color: #515151;
}
.emailColor {
    color: #1d1d1d;
}
.sm-cancel {
    cursor: pointer;
    margin-right: -65px;
}
.normal_input_mask {
    height: 36px !important;
    font-size: 16px !important;
    top: 4px;
    color: #000;
}
.font14 {
    font-size: 14px !important;
}
@media only screen and (min-width: 768px) {
    .admin1.sub_header_bg {
        height: 120px !important;
    }
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem)
            calc(0.75em + 0.375rem) no-repeat !important;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #c6c6c6 !important;
    padding-right: calc(0.75em + 2.3125rem);
}
.contact-type-container > *,
.contact-name-container > * {
    border: none;
}
.contact-container {
    border-bottom: 1px solid #ccc;
}
.contact-remove,
.contact-add {
    display: flex;
    justify-content: flex-end;
}
.addNewContact-container {
    border-bottom: 1px solid #ccc;
}
.contact-container:last-child {
    border: none;
}
#addNContct {
    margin-bottom: 20px;
    min-width: 140px;
}
.contact-type-new {
    line-height: 3;
    height: 48px;
}
.bottom-small-view {
    display: none;
}
.m-view-b-t {
    display: flex;
}
.m-bottom-btn-t {
    display: block;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .bottom-small-view {
        display: flex;
    }
    .m-view-b-t,
    .m-bottom-btn-t {
        display: none;
    }
}
.error {
    color: #f00;
    display: block;
    top: -15px;
    position: relative;
}
</style>
<style lang="scss">
.newContact-container {
    & .FloatingTool,
    .my-custom-floating-label {
        width: 100% !important;
        & .input__container {
            width: 100% !important;
            & .custom-select {
                width: 100% !important;
            }
        }
    }
}
</style>
