var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"vector-sidebar"}},[_c('div',{attrs:{"id":"vector-header"}},[_c('span',{staticClass:"form-floating custom-form-floating inline-input",attrs:{"id":"latitude-input"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.start_point.Latitude),expression:"start_point.Latitude"}],staticClass:"form-control custom-floating-label",class:{
                    'is-invalid': !_vm.validLatitude,
                    'is-valid': _vm.validLatitude,
                },attrs:{"type":"float","id":"floatingInput","placeholder":"Latitude","aria-describedby":"start-point-lat-feedback"},domProps:{"value":(_vm.start_point.Latitude)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.start_point, "Latitude", $event.target.value)},_vm.update_start_point]}}),_c('label',{attrs:{"for":"floatingInput"}},[_vm._v("Latitude")])]),_c('span',{staticClass:"form-floating custom-form-floating inline-input",attrs:{"id":"longitude-input"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.start_point.Longitude),expression:"start_point.Longitude"}],staticClass:"form-control custom-floating-label",class:{
                    'is-invalid': !_vm.validLongitude,
                    'is-valid': _vm.validLongitude,
                },attrs:{"type":"number","id":"floatingInput","placeholder":"Longitude","aria-describedby":"start-point-lon-feedback"},domProps:{"value":(_vm.start_point.Longitude)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.start_point, "Longitude", $event.target.value)},_vm.update_start_point]}}),_c('label',{attrs:{"for":"floatingInput"}},[_vm._v("Longitude")])]),_c('button',{staticClass:"vector-btn",class:_vm.activeMapTool == 'vectorStartPoint'
                    ? 'btn-selected'
                    : 'btn-unselected',attrs:{"id":"select-start-btn"},on:{"click":_vm.select_start_point}},[_vm._v(" Select point on map ")]),_c('span',{staticClass:"form-floating custom-form-floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.planning_time),expression:"planning_time"}],staticClass:"form-control custom-floating-label",class:{
                    'is-invalid': _vm.planning_time <= 0,
                    'is-valid': _vm.planning_time > 0,
                },attrs:{"type":"number","id":"floatingInput","placeholder":"Hours","aria-describedby":"planning-time-feedback"},domProps:{"value":(_vm.planning_time)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.planning_time=$event.target.value},_vm.update_planning_time]}}),_c('label',{attrs:{"for":"floatingInput"}},[_vm._v("Planning hours")])]),(_vm.all_types_valid)?_c('div',{staticClass:"header-msg",attrs:{"id":"endpoint"}},[_vm._v(" "+_vm._s(_vm.endpointText)+" ")]):_c('div',{staticClass:"header-msg error-msg",attrs:{"id":"validation-error-msg"}},[_vm._v(" Repeated vector types are not allowed. ")])]),_c('div',{attrs:{"id":"vector-list"}},_vm._l((_vm.vectors),function(vector,index){return _c('div',{key:index},[_c('div',{staticClass:"vector-item",class:{ 'red-outline': !vector.type_is_valid }},[_c('div',{staticClass:"vector-item-header"},[_c('h4',[_vm._v("Vector "+_vm._s(index + 1))])]),_c('div',{staticClass:"vector-interacts"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"nav-buttons",class:{
                                    disabled: index == 0,
                                },attrs:{"title":"Move vector up","disabled":index == 0},on:{"click":function($event){return _vm.move_vector_up(index)}}},[_c('img',{attrs:{"src":require("@/assets/arrow-up.png")}})])]),_c('div',{staticClass:"col-10 vector-inputs"},[_c('div',{staticClass:"form-floating custom-form-floating"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(vector.type),expression:"vector.type"}],staticClass:"form-select",class:{
                                        'is-invalid': !vector.type_is_valid,
                                        'is-valid': vector.type_is_valid,
                                    },attrs:{"id":"vector-type"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(vector, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.validate_vector_type]}},_vm._l((_vm.vector_types),function(vector_type){return _c('option',{key:vector_type,domProps:{"value":vector_type}},[_vm._v(_vm._s(vector_type))])}),0),_c('label',{attrs:{"for":"vector-type"}},[_vm._v("Vector type")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"nav-buttons delete-btn",attrs:{"title":"Delete vector"},on:{"click":function($event){return _vm.remove_vector(index)}}},[_c('img',{attrs:{"src":require("@/assets/delete.svg")}})])]),_c('div',{staticClass:"col-10 vector-inputs"},[_c('span',{staticClass:"form-floating custom-form-floating inline-input"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(vector.direction),expression:"vector.direction"}],staticClass:"form-select",attrs:{"id":"vector-direction"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(vector, "direction", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.set_vector_direction(
                                            vector,
                                            index,
                                            $event.target.value
                                        )}]}},_vm._l((Object.keys(
                                            _vm.vector_directions
                                        )),function(direction){return _c('option',{key:direction,domProps:{"value":direction}},[_vm._v(" "+_vm._s(direction)+" ")])}),0),_c('label',{attrs:{"for":"vector-direction"}},[_vm._v("Direction")])]),_c('span',{staticClass:"form-floating custom-form-floating inline-input",attrs:{"id":"degrees-input"}},[_c('input',{ref:'vectorDegreesInput-' + index,refInFor:true,staticClass:"form-control custom-floating-label",class:{
                                        disabled: !vector.show_direction_input,
                                    },attrs:{"value":"0","type":"number","id":"floatingInput","placeholder":"Degrees","disabled":!vector.show_direction_input},on:{"input":function($event){return _vm.set_vector_degrees(
                                            vector,
                                            index,
                                            $event.target.value
                                        )}}}),_c('label',{class:{
                                        disabled: !vector.show_direction_input,
                                    },attrs:{"for":"floatingInput"}},[_vm._v("Degrees")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"nav-buttons",class:{
                                    disabled: index == _vm.vectors.length - 1,
                                },attrs:{"title":"Move vector down","disabled":index == _vm.vectors.length - 1},on:{"click":function($event){return _vm.move_vector_down(index)}}},[_c('img',{attrs:{"src":require("@/assets/arrow-down.png")}})])]),_c('div',{staticClass:"col-10 vector-inputs"},[_c('span',{staticClass:"form-floating inline-input custom-form-floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(vector.velocity),expression:"vector.velocity"}],staticClass:"form-control custom-floating-label",attrs:{"type":"number","id":"vector-speed","placeholder":"Velocity"},domProps:{"value":(vector.velocity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(vector, "velocity", $event.target.value)},function($event){return _vm.update_vector_velocity(
                                            index,
                                            $event.target.value
                                        )}]}}),_c('label',{attrs:{"for":"floatingInput"}},[_vm._v("Velocity")])]),_c('div',{staticClass:"form-floating inline-input custom-form-floating",attrs:{"id":"vector-speed-unit"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(vector.speed_unit),expression:"vector.speed_unit"}],staticClass:"form-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(vector, "speed_unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.update_vector_velocity_units(
                                            index,
                                            $event.target.value
                                        )}]}},_vm._l((_vm.vector_units),function(unit){return _c('option',{key:unit,domProps:{"value":unit}},[_vm._v(_vm._s(unit))])}),0),_c('label',{attrs:{"for":"vector-direction"}},[_vm._v("Velocity units")])])])])])])])}),0),_c('div',{attrs:{"id":"vector-footer"}},[_c('span',{staticClass:"clear-text",attrs:{"id":"remove-all-vectors"},on:{"click":_vm.remove_all_vectors}},[_vm._v(" Remove All Vectors ")]),_c('button',{staticClass:"vector-btn",attrs:{"id":"add-vector"},on:{"click":_vm.add_new_vector}},[_vm._v(" Add Vector ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }