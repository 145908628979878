<template>
    <b-modal
        :visible="isVisible"
        :hide-footer="true"
        :hide-header="true"
        size="xl"
        @hide="hide()"
    >
        <h3>New Contact</h3>
        <b-container>
            <b-row no-gutters>
                <b-col>
                    <CustomValidateInput
                        :config="{
                            label: 'First Name',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('FirstName')"
                    >
                        <b-form-input
                            :state="validateState('FirstName')"
                            name="FirstName"
                            v-model="$v.contact.FirstName.$model"
                            autocomplete="off"
                            aria-describedby="FirstName-feedback"
                            maxLength="10"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="FirstName-feedback"
                        >
                            Please enter First Name
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Last Name',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('LastName')"
                    >
                        <b-form-input
                            :state="validateState('LastName')"
                            name="LastName"
                            v-model="$v.contact.LastName.$model"
                            autocomplete="off"
                            aria-describedby="LastName-feedback"
                            maxLength="10"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="LastName-feedback"
                        >
                            Please enter Last Name
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Title',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Title')"
                    >
                        <b-form-input
                            :state="validateState('Title')"
                            name="Title"
                            v-model="$v.contact.Title.$model"
                            autocomplete="off"
                            aria-describedby="Title-feedback"
                            maxLength="10"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Title-feedback"
                        >
                            Please enter Title
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Organization Name',
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Org_Id')"
                    >
                        <b-form-group>
                            <b-form-select
                                v-model="$v.contact.Org_Id.$model"
                                :options="organizations"
                                text-field="Org_Name"
                                value-field="Org_Id"
                                :state="validateState('Org_Id')"
                                aria-describedby="org_Id-feedback"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Organization</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                            <b-form-invalid-feedback
                                class="invalid-text-custom"
                                id="clientName-feedback"
                            >
                                Please select a Organization
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </CustomFloatingLabel>
                    <CustomValidateInput
                        :config="{
                            label: 'Contact Comment',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('ContactComment')"
                    >
                        <b-form-textarea
                            :state="validateState('ContactComment')"
                            name="ContactComment"
                            v-model="$v.contact.ContactComment.$model"
                            autocomplete="off"
                            aria-describedby="ContactComment-feedback"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="ContactComment-feedback"
                        >
                            Please enter Contact Comment
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Contact Status',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('ContactStatus')"
                    >
                        <b-form-input
                            :state="validateState('ContactStatus')"
                            name="ContactStatus"
                            v-model="$v.contact.ContactStatus.$model"
                            autocomplete="off"
                            aria-describedby="ContactStatus-feedback"
                            maxLength="10"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="ContactStatus-feedback"
                        >
                            Please enter Contact Status
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Custom Field1',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('CustomField1')"
                    >
                        <b-form-textarea
                            :state="validateState('CustomField1')"
                            name="CustomField1"
                            v-model="$v.contact.CustomField1.$model"
                            autocomplete="off"
                            aria-describedby="CustomField1-feedback"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="CustomField1-feedback"
                        >
                            Please enter Custom Field1
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Custom Field2',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('CustomField2')"
                    >
                        <b-form-textarea
                            :state="validateState('CustomField2')"
                            name="CustomField2"
                            v-model="$v.contact.CustomField2.$model"
                            autocomplete="off"
                            aria-describedby="CustomField2-feedback"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="CustomField2-feedback"
                        >
                            Please enter Custom Field2
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                </b-col>
                <b-col>
                    <CustomValidateInput
                        :config="{
                            label: 'Pager',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Pager')"
                    >
                        <b-form-input
                            :state="validateState('Pager')"
                            name="Pager"
                            v-model="$v.contact.Pager.$model"
                            autocomplete="off"
                            aria-describedby="Pager-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Pager-feedback"
                        >
                            Please enter Pager
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Fax',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Fax')"
                    >
                        <b-form-input
                            :state="validateState('Fax')"
                            name="Fax"
                            v-model="$v.contact.Fax.$model"
                            autocomplete="off"
                            aria-describedby="Fax-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Fax-feedback"
                        >
                            Please enter Fax
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Phone',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Phone')"
                    >
                        <b-form-input
                            :state="validateState('Phone')"
                            name="Phone"
                            v-model="$v.contact.Phone.$model"
                            autocomplete="off"
                            aria-describedby="Phone-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Phone-feedback"
                        >
                            Please enter Phone
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Ext',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Ext')"
                    >
                        <b-form-input
                            :state="validateState('Ext')"
                            name="Ext"
                            v-model="$v.contact.Ext.$model"
                            autocomplete="off"
                            aria-describedby="Ext-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Ext-feedback"
                        >
                            Please enter Ext
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Cell',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('Cell')"
                    >
                        <b-form-input
                            :state="validateState('Cell')"
                            name="Cell"
                            v-model="$v.contact.Cell.$model"
                            autocomplete="off"
                            aria-describedby="Cell-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="Cell-feedback"
                        >
                            Please enter Cell
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Primary E-mail',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('PrimaryEmail')"
                    >
                        <b-form-input
                            :state="validateState('PrimaryEmail')"
                            name="PrimaryEmail"
                            v-model="$v.contact.PrimaryEmail.$model"
                            autocomplete="off"
                            aria-describedby="PrimaryEmail-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="PrimaryEmail-feedback"
                        >
                            Please enter Primary Email
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <CustomValidateInput
                        :config="{
                            label: 'Secondary E-mail',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                            labelOffset: {
                                top: 2,
                                left: 18,
                            },
                        }"
                        :isValid="validated('SecondaryEmail')"
                    >
                        <b-form-input
                            :state="validateState('SecondaryEmail')"
                            name="SecondaryEmail"
                            v-model="$v.contact.SecondaryEmail.$model"
                            autocomplete="off"
                            aria-describedby="SecondaryEmail-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="SecondaryEmail-feedback"
                        >
                            Please enter Secondary Email
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <b-row no-gutters>
                        <b-col cols="6"> </b-col>
                        <b-col cols="6">
                            <b-button
                                rounded
                                class="saveButton"
                                @click="saveContact()"
                                :disabled="saveBtnDisable"
                                >Save</b-button
                            >
                            <b-button class="cancelSave" @click="cancelSave()"
                                >Cancel</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>
<script>
import { required, integer, email } from 'vuelidate/lib/validators';
import CustomValidateInput from '../CustomValidateInput';
import CustomFloatingLabel from '../CustomFloatingLabel';
import { mapActions, mapMutations, mapState } from 'vuex';
import { eTrackerService } from '../../services/eTracker.service';

export default {
    name: 'AddNewContactModal',
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        organizations: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        CustomValidateInput,
        CustomFloatingLabel,
    },
    validations() {
        return {
            contact: {
                FirstName: {
                    required,
                },
                LastName: {
                    required,
                },
                Title: {
                    required,
                },
                Org_Id: {
                    required,
                },
                ContactComment: {
                    required,
                },
                ContactStatus: {
                    required,
                },
                CustomField1: {
                    required,
                },
                CustomField2: {
                    required,
                },
                Pager: {
                    required,
                    integer,
                },
                Fax: {
                    required,
                    integer,
                },
                Phone: {
                    required,
                    integer,
                },
                Ext: {
                    required,
                },
                Cell: {
                    required,
                    integer,
                },
                PrimaryEmail: {
                    required,
                    email,
                },
                SecondaryEmail: {
                    required,
                    email,
                },
            },
        };
    },
    data() {
        return {
            contact: {
                FirstName: null,
                LastName: null,
                Title: null,
                Org_Id: null,
                ContactComment: null,
                CustomField1: null,
                CustomField2: null,
                Pager: null,
                Fax: null,
                Phone: null,
                Ext: null,
                Cell: null,
                PrimaryEmail: null,
                SecondaryEmail: null,
                ContactStatus: null,
            },
            saveBtnDisable: false,
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        validateState(name) {
            const { $dirty, $error } = this.$v.contact[name];
            return $dirty ? !$error : null;
        },
        cancelSave() {
            this.$emit('hideAddNewContact');
        },
        saveContact() {
            this.saveBtnDisable = true;
            this.$v.contact.$touch();
            if (this.$v.contact.$anyError) {
                this.saveBtnDisable = false;
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage:
                        'Please correct the Errors before saving.',
                });
                return;
            }
            eTrackerService
                .addNewContact(this.cleanData())
                .then((res) => {
                    this.setToastMessage({
                        showSuccess: true,
                        successToastMessage: 'Successfully added the Contact',
                    });
                    this.$emit('hideAddNewContact');
                })
                .catch((error) => {
                    this.setToastMessage({
                        showFailure: true,
                        failureToastMessage:
                            'Please correct the Errors before saving.',
                    });
                });
        },
        hide() {
            this.$emit('hideAddNewContact');
        },
        cleanData() {
            let data = {
                Org_Id: this.contact.Org_Id,
                First_Name: this.contact.FirstName,
                Last_Name: this.contact.LastName,
                Title: this.contact.Title,
                Phone: this.contact.Phone,
                Phone_Extension_Number: this.contact.Ext,
                Pager: this.contact.Pager,
                Fax: this.contact.Fax,
                Cell: this.contact.Cell,
                Primary_Email: this.contact.PrimaryEmail,
                Secondary_Email: this.contact.SecondaryEmail,
                Comments: this.contact.ContactComment,
                ContactStatus: this.contact.ContactStatus,
                CustomField1: this.contact.CustomField1,
                CustomField2: this.contact.CustomField2,
            };
            return data;
        },
    },
    computed: {
        validated() {
            return (status) => {
                const { $dirty } = this.$v.contact[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
    },
};
</script>
<style scoped>
.my-custom-floating-label {
    margin-bottom: 20px;
    display: inline-block;
}

.errorMsg {
    margin-top: -20px;
    margin-bottom: 20px;
}

.errorMsg_input {
    position: relative;
    top: -20px;
    width: 499px;
}

.errorMsg_input span {
    float: right;
    position: relative;
    top: -40px;
    right: 15px;
    /* right: 88px; */
}

.normal_input {
    height: 48px !important;
}

.textarea_input {
    height: 95px !important;
}

select {
    appearance: none;
    border-radius: 0px;
    height: 48px !important;
    width: 475px;
}

select.selectbox {
    border: 1px solid #c6c6c6;
    padding-left: 7px;
    color: -internal-light-dark(black, white);
}

.selectbox:hover {
    border: 1px solid #c6c6c6;
}

.smalltext {
    color: #000;
    margin: -15px 0px 20px;
    font-size: 13px;
}

.typeAhead {
    border-radius: 0px !important;
    height: 48px !important;
    width: 475px;
}

.form-floating-label textarea {
    margin-bottom: 0;
    color: #626262;
    font-size: 16px;
    /* font-weight: 300; */
    padding: 35px 1rem 1rem 20px !important;
    width: 475px;
    height: 134px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
    border-radius: 3px;
}
.form-floating-label label {
    color: #626262;
    font-size: 16px;
    position: absolute;
    left: 17px;
    padding-top: 10px;
    transition: all 0.2s ease-in;
    font-family: var(--ffr);
}

.form-floating-label textarea ~ label {
    font-size: 16px;
    font-weight: 400;
}
.form-floating-label textarea:focus,
.form-floating-label.has-value textarea {
    border-radius: 0px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
}
.form-floating-label textarea:focus ~ label,
.form-floating-label.has-value textarea ~ label {
    font-size: 12px;
    color: rgb(98, 98, 98);
    font-weight: 400;
}

.contactinfo {
    color: #161616;
    font-size: 25px;
}

.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.crossIcon {
    margin-left: 150px;
    margin-top: -25px;
    width: 15px;
}
.input__container.input__container--content input,
.input__container.input__container--content .label__placeholder {
    transform: translate3d(0, 6px, 0);
    /* width: 94% !important; */
    /* left:12px; */
}
.sm-text {
    margin-top: 0 !important;
}
small {
    color: #515151;
    font-family: var(--ffr);
    font-size: 14px;
}
.nameColor {
    color: #515151;
}
.emailColor {
    color: #1d1d1d;
}
.sm-cancel {
    cursor: pointer;
    margin-right: -65px;
}
.normal_input_mask {
    height: 36px !important;
    font-size: 16px !important;
    top: 4px;
    color: #000;
}
.font14 {
    font-size: 14px !important;
}
@media only screen and (min-width: 768px) {
    .admin1.sub_header_bg {
        height: 120px !important;
    }
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem)
            calc(0.75em + 0.375rem) no-repeat !important;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #c6c6c6 !important;
    padding-right: calc(0.75em + 2.3125rem);
}
.contact-type-container > *,
.contact-name-container > * {
    border: none;
}
.contact-container {
    border-bottom: 1px solid #ccc;
}
.contact-remove,
.contact-add {
    display: flex;
    justify-content: flex-end;
}
.addNewContact-container {
    border-bottom: 1px solid #ccc;
}
.contact-container:last-child {
    border: none;
}
#addNContct {
    margin-bottom: 20px;
    min-width: 140px;
}
.contact-type-new {
    line-height: 3;
    height: 48px;
}
.bottom-small-view {
    display: none;
}
.m-view-b-t {
    display: flex;
}
.m-bottom-btn-t {
    display: block;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .bottom-small-view {
        display: flex;
    }
    .m-view-b-t,
    .m-bottom-btn-t {
        display: none;
    }
}
.error {
    color: #f00;
    display: block;
    top: -15px;
    position: relative;
}
.saveButton {
    background-color: #006395;
    width: 100px;
    position: relative;
    float: right;
    margin-right: 10px;
}
.cancelSave {
    position: relative;
    float: right;
    margin-right: 10px;
}
</style>
<style lang="scss">
.newContact-container {
    & .FloatingTool,
    .my-custom-floating-label {
        width: 100% !important;
        & .input__container {
            width: 100% !important;
            & .custom-select {
                width: 100% !important;
            }
        }
    }
}
</style>
